.signin-container {
  width: 100%;
  height: 100vh;
  background-color: #536471;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-wrapper {
  width: 60%;
  border: none;
  max-height: fit-content;
  padding: 1rem 3rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  background-color: black;
  border-radius: 10px;
  color: white;
}
.logo {
  width: fit-content;
  margin: 0 auto;
}
.logo button {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  background-color: transparent;
  border: none;
  color: white;
}
.signin-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.head p {
  color: white;
  font-size: 2rem;
  font-weight: 500;
  width: fit-content;
  margin: auto;
}
.ext-auth {
  margin: auto;
}
.divider {
  width: 70%;
  margin: auto;
}
.outer-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.outer-form div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
  margin: auto;
}
.outer-form div input {
  height: 50px;
  font-size: 1.1rem;
  font-weight: 500;
  border: 1px solid #536471;
  background-color: black;
  border-radius: 7px;
  padding: 0.5rem 0;
  padding-left: 1rem;
  color: white;
}
.outer-form div button {
  height: 60px;
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 30px;
}
.outer-form div p span {
  color: #1d9bf0;
}
@media screen and (max-width: 768px) {
  .inner-wrapper {
    width: 100%;
    height: 100vh;
  }
}
