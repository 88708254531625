
body{
  --twitter-color:#1d9cf0;
  --twitter-dark-color: #148ddd;
  --twitter-background:#000;
  --twitter-text-color: #0F1419;
  --twitter-background2: #767676;
  --twitter-background3: #858585;
}

/* rgb(118, 118, 118), rgb(133, 133, 133)) */
