.widgetCard{
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  padding: 9px 15px;
  border: 1px solid #303030;
  border-radius: 10px;
  padding: 20px;
  color: white;

    padding: 9px 15px;
}
.widgetCard:hover{
    background-color: var(--twitter-background);
}
.widgetText{
  width: 70%;
  color: white;
}
.widgetImg{
    width: 68px;
    /* border: 1px solid teal; */
    height: 68px;
    border-radius: 15px;
    
}
.widgetImg>img{
width: 68px;
height: 68px;
object-fit: cover;
border-radius: 15px;
}
.widgetTag{
    font-size: small;
}
.widgetContent {
  color: #0f1419;
  font-weight: 600;
  margin-top: 5px;
  color: gray;
}
