.sidebarOption {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  margin: 0;
  padding: 0;
  background-color: unset;
}

.sidebarOption > .MuiSvgIcon-root {
  padding-right: 1vw;
  font-size: 27px;
}

.sidebarOption--active {
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .sidebarOption > span {
    display: none;
  }
}
