/* *{
    margin: 0;
} */

.profile {
  flex: 0.51 1;
  max-width: fit-content;
  overflow-y: scroll;
  margin-left: auto;
  color: white;
}
.username {
  margin-top: 10px;
  margin-left: -12px;
  font-size: 14px;
}
.profile::-webkit-scrollbar {
  display: none;
}
.profile_name {
  color: white;
  font-family: helvetica;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 3px;
}
.profile_back_arrow {
  color: white;
}
.name_div {
  display: flex;
  padding: 10px;
    padding-left: 0px;
  color: gray;
  margin-left: -25px;
  fotn: f;
  font-size: 13px;
  font-weight: 600;
}
.MuiSvgIcon-root {
  font-size: small;
  margin-left: 20px;
  padding: 10px 20px 10px 10px;
  width: 50px;
}

.profile_background_div {
  margin: 0;
  /* position:absolute; */
  scroll-behavior: smooth;
  max-height: 300px;
  height: 280px;
  display: flex;

  /* border: 1px red solid; */
}
.profile_background {
  width: 100%;
  border-radius: 10px;
}

.profile_pic_div {
  /* border: 1px red solid; */
  /* position: relative; */
  margin-top: -80px;
  margin-left: 20px;

  background-size: cover;
}
.profile_pic_div .MuiSvgIcon-root {
  width: 50px;
  height: 50px;
  margin-left: 13px;
}
.profile_pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* width: 150px; */
  border: 4px white solid;
}
.profile_pic_text {
  text-align: left;
  font-weight: bold;
  margin-bottom: 1px;
  font-size: 20px;
}
.editprofile_btn {
 color: white;
  background: none;
    background-color: rgba(0, 0, 0, 0);
  background-color: unset;
  display: flex;
  border: 2px solid;
  width: 150px;
  float: right;
  justify-content: center;
  border-radius: 10px;
}
.editprofile_btn > button {
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 25px;
  border: 1px solid var(--twitter-background);
  color: white;
}
.profile_place_div {
  display: flex;
  /* border: 1px red solid; */
}
.center_profile {
  padding: 10px 0;
  margin-left: -15px;
}
.profile_follwer_div {
  display: flex;
}
.profile_follwer_div p {
  margin-left: 20px;
}
.profile_follwer_div p:hover {
  text-decoration: underline;
  cursor: pointer;
}
.profile_post_header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
.profile_post_header div {
  border: 1px solid var(--twitter-background);
  padding: 10px 60px;
  margin-top: 20px;
}
.profile_post_header div:hover {
  background-color: var(--twitter-background);
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .profile {
    flex: 0.7;
  }
}
@media screen and (max-width: 768px) {
  .profile {
    flex: 0.8;
    padding-right: 10px;
  }
}
