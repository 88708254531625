.widgets {
  flex: 0.32;
  /* flex-grow: 0.32; */
  /* width: 32%; */
  /* border-right: 1px solid grey; */
}
.widgetWrapp {
  /* border: 1px solid red; */
  width: 80%;
  margin: auto;
  margin-top: 5px;
}

.searchTwitter {
  align-items: center;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  margin-top: 18px;
  color: white;
  background: #303030;
}
.searchTwitter > i {
  font-size: 23px;
}
.searchTwitter > input {
  border: 0;
  font-size: 15px;
  margin-left: 10px;
  outline: none;
  width: 80%;
  background: unset;
  color: white;
}
.widgetFeed {
  border-radius: 12px;
  margin-top: 15px;
  background-color: #f2f6f8;
  padding-bottom: 10px;
}
.widgetHeader {
  font-size: large;
  font-weight: 700;
  padding: 15px;
  color: white;
}

@media screen and (max-width: 1000px) {
  .widgets {
    display: none;
  }
}
