* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: black;
}
.signup-container {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: black;
  color: white;
}
.frontpageinfo {
  max-width: 350px;
  font-size: 12px;
  font-family: helvetica;
  color: gray;
  margin-top: 10px;
}

.poster {
  background-image: url(https://th.bing.com/th/id/OIG1..rD..ycxRpBXou21XkY0?pid=ImgGn);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-width: 400px;
}
.largelogo {
  background: none;
}
.poster .Logo {
  width: 100%;
  height: fit-content;
  max-width: 500px;
  background-color: unset;
}

.signup-form-container {
  padding: 5rem 0rem 0rem 3rem;
  background-color: black;
  color: #d9d9d9;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 40%;
  margin-top: -10px;
  padding-top: 15px;
}
.ariapluslogo {
  width: 60px;
}
.head-content {
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
}
.head-content > div:nth-child(2) h1 {
  font-size: 40px;
  font-family: helvetica;
  color: white;
}
.head-content > div:nth-child(3) h2 {
  font-size: 20px;
  color: white;
}

.signup-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  min-width: 300px;
}
.signup-btns {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.signup-btns > button {
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  max-width: 300px;
  min-height: 50px;
}
.signup-btns button .MuiSvgIcon-root {
  margin: 0;
  padding: 0.3rem 0.2rem;
}
.signup-btns > button:nth-child(1) {
  background-color: white;
}
.signup-btns > button:nth-child(1):hover {
  background-color: rgb(199, 199, 199);
}
.signup-btns > button:nth-child(2) {
  background-color: #3b5998;
  color: white;
}
.signup-btns > button:nth-child(2):hover {
  background-color: #2f4677;
}
.divider {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.divider > div:nth-child(1),
.divider > div:nth-child(3) {
  width: 45%;
  height: 1px;
  background-color: rgba(202, 202, 202, 0.671);
}

.manual-signup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.manual-btn > button {
  background-color: #1d9bf0;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  max-width: 300px;
  padding: .7rem 2rem;
  width: 100%;
  font-family: helvetica;
  font-weight: 600;
}
.manual-signup p {
  font-size: 0.8rem;
}
.manual-signup p span {
  color: #1d9bf0;
}

.signin-redirect-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
}

.signin-redirect-box p {
  font-size: 1.2rem;
  font-weight: 500;
}
.signin-redirect-box button {
  border: 1px solid #536471;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: #1d9bf0;
  background-color: transparent;
}
.signin-redirect-box button:hover {
  background-color: #1d9cf02c;
}

/* signup form manual signup  */
.outer-popup {
  background-color: #242d3428;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-form {
  position: relative;
  height: fit-content;
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 8rem 5rem 2rem 3rem;
  background-color: #000000;
  color: white;
  max-width: 700px;
  min-width: 300px;
}
.close-btn {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: transparent;
  border: none;
}
.back-btn {
  position: absolute;
  left: 44%;
  top: 5%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  width: 550px;
}
.form p {
  font-size: 20px;
  font-weight: 600;
  font-family: helvetica;
  text-align: center;
}
.form .signup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.signup div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  font-family: helvetica;
  font-size: 15px;
}
.signup div input {
  background-color: #000;
  border: 1px solid #242424;
  border-radius: 10px;
  color: gray;
  font-size: 1rem;
  height: 60px;
  padding-left: 1rem;
  width: 50%;
  align-self: center;
}
.signup div input:focus {
  outline: 2px solid #1d9bf0;
  border: none;
  color: white;
  font-weight: 500;
}
.signup div a {
  color: #1d9bf0;
  cursor: pointer;
}
.signup div button {
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0rem;
  padding: .8rem 0;
  width: 55%;
  background-color: white;
  align-self: center;
  border: 0px;
}
.signup div button:hover {
  background-color: rgba(255, 255, 255, 0.753);
}
@media screen and (max-width: 1000px) {
  .signup-container {
    flex-direction: column-reverse;
    width: fit-content;
    height: fit-content;
  }
  .signup-container > div:nth-child(2) {
    width: fit-content;
    padding: 2rem 5rem;
  }
  .signup-container > div:nth-child(1) {
    display: none;
  }
  .popup-form {
    background-color: black;
    min-width: -moz-available;
    flex-direction: inherit;
    padding-left: 0px;
    padding-right: 0px;
  }
}
