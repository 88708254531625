.feed {
  flex: 0.50 1;
  margin-left: auto;
  width: 50%;
}

.feed::-webkit-scrollbar {
  display: none;
}
.headerimage {
  width: -moz-available;
  border-radius: 10px;
}
.active {
  align-content: center;
  align-self: center;
  border-bottom: 4px solid blue;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  justify-self: center;
  padding-bottom: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 90px;
}
.feed_header {
  border: 1px solid #303030;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 15px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  color: white;
  border-radius: 10px;
  margin-top: 15px;
  gap: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;

}

@media screen and (max-width: 1000px) {
  .feed {
    flex: 0.7;
  }
}
@media screen and (max-width: 768px) {
  .feed {
    flex: 0.8 1;
    padding-right: 15px;
  }
}
