.post {
  box-sizing: border-box;
  display: flex;
  padding: 10px 12px;
  width: 100%;
  border: 1px solid #303030;
  margin-bottom: 10px;
  border-radius: 10px;
  color: white;
}
.post_avatar .MuiSvgIcon-root {
  height: 30px;
  width: 30px;
}
.post_body {
  padding-left: 10px;
  width: 100%;
}
.post_header {
  display: flex;
  justify-content: space-between;
}

.post_headerNames {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 13px;
  margin-top: -10px;
}
.post_description > p {
  font-size: 1.1rem;
}
.post_image {
  margin-bottom: 10px;
  width: auto;
  margin-left: -50px;

}
.post_image > img {
  width: 100%;
  border-radius: 1rem;
  object-fit: contain;
  margin-bottom: 1rem;
}
.post_actions {
  display: flex;
  margin-top: -15px;
  margin-top: -15px;
  justify-content: space-evenly;
}
.post_actions > div {
  width: 25%;
  display: flex;
  align-items: center;
}
.post_actions > div > span {
  margin-left: 7px;
}
.post_actions i {
  font-size: 20px;
}
.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.css-16bxh3m {
  width: 40px;
  height: 40px;
}
